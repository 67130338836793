.nav-bar {
  top: 8px;
  z-index: 15;
  display: flex;
  justify-content: center;
  background-color: white;

  .container-nav-bar {
    display: flex;
    align-content: center;
    justify-content: space-between;
    width: 1180px;
    align-items: center;
    flex-direction: row;
    padding: 28px 0px;
    width: 100%;
    margin: 0 auto;

    .logo-title {
      display: flex;
      cursor: pointer;
      align-items: center;

      color: white;

      img {
        margin-right: 7px;
      }

      span {
        font-family: "InterMedium";
        font-size: 18px;

        letter-spacing: -0.05em;
        color: $secondary-color;
      }
    }

    .nav-links {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-grow: 1;
      a {
        color: $quaternary-color;
        padding: 2px 12px;
        &:hover {
          color: $primary-color;
          /*  border-color: #6935d7; */
        }
      }
      a.active {
        color: $primary-color;
        background: rgba(10, 75, 255, 0.3);
        border-radius: 5px;
      }
    }

    .container-user {
      display: flex;

      justify-content: right;
      &:hover {
        background-color: rgb(245, 245, 245);
      }
      .display-user {
        display: flex;
        align-items: center;
        justify-content: space-between;
        cursor: pointer;
        border: 0px;
        appearance: none;
        font-size: 1rem;
        background-color: transparent;
        text-align: left;
        text-decoration: none;
        box-sizing: border-box;
        padding: 0.4rem;
        color: rgb(0, 0, 0);
        border-radius: 0.25rem;
      }

      .name-email {
        span {
          font-size: 0.875rem;
          line-height: 1.55;
          text-decoration: none;
          font-weight: 500;
        }
        p {
          color: rgb(134, 142, 150);
          font-size: 0.75rem;
          line-height: 1.55;
          text-decoration: none;
        }
      }

      img {
        cursor: pointer;
      }

      .user-img {
        width: 40px;
        height: 40px;
        object-fit: cover;
        border-radius: 100px;
        margin-right: 8px;
      }
    }
  }

  a {
    text-decoration: none;
    color: white;
    cursor: pointer;
  }
}

@media (max-width: 1200px) {
  .container-nav-bar {
    max-width: 1180px;
  }
}

@media (min-width: 1200px) {
  .container-nav-bar {
    max-width: 1180px;
  }
}

@media (min-width: 1300px) {
  .container-nav-bar {
    max-width: 1250px;
  }
}

@media (min-width: 1400px) {
  .container-nav-bar {
    max-width: 1350px;
  }
}
@media (min-width: 1500px) {
  .container-nav-bar {
    max-width: 1450px;
  }
}

@media (min-width: 1600px) {
  .container-nav-bar {
    max-width: 1550px;
  }
}

@media (min-width: 1700px) {
  .container-nav-bar {
    max-width: 1680px;
  }
}
