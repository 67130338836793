.all-consultants-assigned {
  .table-consultants-assigned {
    font-family: "InterRegular";
    font-size: 14px;
    line-height: 17px;
    letter-spacing: -0.05em;
    color: #999ca6;
    width: 100%;
    background-color: white;
    border: 1px solid #e5e9ef;
    border-radius: 5px;
    margin-top: 2rem;

    .th {
      color: $secondary-color;
      padding: 22px 22px;
    }

    thead {
      // border-top-right-radius: 50px;
      color: $secondary-color;

      tr {
        // border-top-right-radius: 50px;
        // padding: 22px 22px !important;

        th {
          padding: 12px;
          text-align: left;
        }
      }
    }

    tbody {
      tr {
      }
      td {
        padding: 12px;
        border-bottom: 1px solid #e6e6e6;
      }
    }
  }
}

.container-confirm-delete {
  p {
    font-family: "Inter";
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    margin-bottom: 1rem;

    letter-spacing: -0.05em;
    color: $secondary-color;
  }

  span {
    color: #de4341;
    font-size: 15px;
  }

  .div-button-choice {
    display: flex;
    justify-content: right;
    margin-top: 25px;
    .btn-no {
      background-color: white;
      color: $secondary-color;
      border: 1px solid #8c98ae;
      border-radius: 5px;
      font-family: "InterMedium";
      margin-top: 1rem;
      font-size: 14px;
      line-height: 18px;
      padding: 10px 14px;
      letter-spacing: -0.05em;
      cursor: pointer;
      &:hover {
        background: $tertiary-color;
      }
    }

    .btn-yes {
      font-family: "InterMedium";
      margin-top: 1rem;
      font-size: 14px;
      line-height: 18px;
      padding: 10px 14px;
      letter-spacing: -0.05em;
      color: white;
      margin-left: 10px;
      background: $primary-color;
      border-radius: 5px;
      cursor: pointer;
      border: none;

      &:hover {
        background: $hover-primary-color;
      }
    }
  }
}
#my-tooltip-multiline > * {
  color: white !important;
}
