.clientBDC {
  font-family: "InterRegular";
  background-color: white;
  border: 1px solid #e3e3e3;
  border-radius: 3px;
  margin-top: 5rem;

  .container-header-clientbdc {
    display: flex;
    padding: 3rem;
  }

  .div-client-infos {
  }

  h1 {
    font-size: 32px;
    line-height: 28px;

    letter-spacing: -0.05em;
    font-family: "FaktumBold";
    color: $secondary-color;
    padding: 10px 30px;
  }

  h2 {
    font-family: "FaktumRegular";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    /* identical to box height */

    color: #b6c0ce;
  }
  img {
    width: 150px;
    border-radius: 15px;
  }

  .div-total-create-client {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 30px;

    .descrip-totals {
      display: flex;
      align-items: center;

      background: #ffffff;
      // border: 1px solid #e5e9ef;
      // border-radius: 10px;
      // box-shadow: 0px 4px 4px rgba(215, 215, 215, 0.25);

      p {
        font-family: "InterMedium";
        margin-right: 5px;
        font-size: 16px;
        line-height: 17px;
        /* identical to box height */

        letter-spacing: -0.05em;

        color: $secondary-color;
      }

      span {
        display: flex;
        font-family: "InterSemiBold";
        font-style: normal;
        font-weight: 700;
        font-size: 20px;
        line-height: 24px;
        letter-spacing: -0.05em;

        color: $secondary-color;
      }
    }
  }

  .nav-control {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 2rem;
  }

  .nav-userbdc {
    width: 200px;
    height: 40px;
    background: #f0f3f8;
    border-radius: 5px;
    font-family: "InterMedium";
    justify-content: space-around;
    font-size: 15px;
    line-height: 20px;
    /* identical to box height, or 143% */
    display: flex;
    align-items: center;
    letter-spacing: -0.05em;
    margin-left: 50px;

    color: #a6aebc;
    button {
      border: none;
      background: #f0f3f8;
      border-radius: 5px;
      color: $quaternary-color;
      padding: 2px 12px;
      text-decoration: none;
      &:hover {
        color: $secondary-color;
        /*  border-color: #6935d7; */
      }
    }
    button:focus {
      padding: 7px;
      color: $quinary-color;
      background: white;
      border-radius: 5px;
      border: none;
    }
  }
}
