.container-all-clients {
  font-family: "InterRegular";
  background-color: white;
  border: 1px solid #e3e3e3;
  border-radius: 3px;
  margin-top: 5rem;

  h1 {
    font-size: 32px;
    line-height: 28px;

    letter-spacing: -0.05em;
    font-family: "FaktumBold";
    color: $secondary-color;
    padding: 32px 50px;
  }

  .search-bar {
    font-family: "InterRegular";
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    line-height: 18px;
    padding: 8px;
    letter-spacing: -0.05em;
    color: #2f2b43;
    background-color: white;
    width: 250px;
    border: 1px solid #e7e7e7;
    border-radius: 5px;
    margin-right: 20px;
  }

  .filter-create-client {
    display: flex;
  }

  .total-mois {
    font-family: "InterMedium";
    font-size: 14px;
    line-height: 17px;
    letter-spacing: -0.05em;
    color: #999ca6;
    text-transform: uppercase;
    margin-left: 13px;
  }

  .div-total-create-client {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 32px 50px;

    .descrip-totals {
      display: flex;
      // align-items: center;

      background: #ffffff;
      // border: 1px solid #e5e9ef;
      // border-radius: 10px;
      // box-shadow: 0px 4px 4px rgba(215, 215, 215, 0.25);

      p {
        font-family: "InterMedium";
        margin-right: 5px;
        font-size: 16px;
        line-height: 17px;
        /* identical to box height */

        letter-spacing: -0.05em;

        color: $secondary-color;
      }

      span {
        display: flex;
        font-family: "InterSemiBold";
        font-style: normal;
        font-weight: 700;
        font-size: 20px;
        line-height: 24px;
        letter-spacing: -0.05em;

        color: $secondary-color;
      }
    }

    button {
      font-family: "InterMedium";

      font-size: 14px;
      line-height: 18px;
      padding: 10px 14px;
      letter-spacing: -0.05em;
      color: white;

      background: $primary-color;
      border-radius: 5px;
      cursor: pointer;
      border: none;

      &:hover {
        background: $hover-primary-color;
      }
    }
  }
  .td-clients {
    color: $primary-color;

    cursor: pointer;
    &:hover {
      text-decoration: underline;
    }
  }
}
