.nav-view {
  width: 200px;
  height: 40px;
  background: #f0f3f8;
  border-radius: 5px;
  font-family: "InterMedium";
  justify-content: space-around;
  font-size: 15px;
  line-height: 20px;
  /* identical to box height, or 143% */
  display: flex;
  align-items: center;
  letter-spacing: -0.05em;

  color: #a6aebc;
  a {
    color: $quaternary-color;
    padding: 2px 12px;
    text-decoration: none;
    &:hover {
      color: $secondary-color;
      /*  border-color: #6935d7; */
    }
  }
  a.active {
    padding: 7px;
    color: $secondary-color;
    background: white;
    border-radius: 5px;
  }
}
