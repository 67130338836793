.bdctocheck-page {
  font-family: "InterRegular";
  background-color: white;
  border: 1px solid #e3e3e3;
  border-radius: 3px;
  margin-top: 5rem;

  h1 {
    font-size: 32px;
    line-height: 40px;
    font-family: "FaktumBold";
    color: $secondary-color;
    padding: 32px 50px;
    letter-spacing: -0.05em;
  }

  .search-create {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 32px 50px;
  }

  button {
    font-family: "InterMedium";

    font-size: 14px;
    line-height: 18px;
    padding: 10px 14px;
    letter-spacing: -0.05em;
    color: white;

    background: $primary-color;
    border-radius: 5px;
    cursor: pointer;
    border: none;

    &:hover {
      background: $hover-primary-color;
    }
  }

  .search-bar {
    font-family: "InterRegular";
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    line-height: 18px;
    padding: 8px;
    letter-spacing: -0.05em;
    color: $secondary-color;
    background-color: white;
    width: 250px;
    border: 1px solid $tertiary-color;
    border-radius: 5px;
    margin-right: 20px;
  }
}
