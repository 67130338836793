.status-non-attribue {
  background: rgba(221, 92, 100, 0.3);
  margin-top: 11px;
  display: flex;
  justify-content: center;

  padding: 5px 7px;
  font-family: "InterMedium";

  font-size: 14px;
  line-height: 17px;
  /* identical to box height */
  border-radius: 20px;
  letter-spacing: -0.05em;

  color: #dd5c64;
}

.status-partiellement-attribue {
  background: rgba(214, 150, 97, 0.3);
  border-radius: 20px;
  margin-top: 11px;
  display: flex;
  justify-content: center;

  padding: 5px 7px;

  font-family: "InterMedium";

  font-size: 14px;
  line-height: 17px;

  letter-spacing: -0.05em;

  color: #d69661;
}

.status-attribue {
  margin-top: 11px;
  display: flex;
  justify-content: center;
  background: rgba(90, 168, 133, 0.3);

  padding: 5px 7px;
  font-family: "InterMedium";

  font-size: 14px;
  line-height: 17px;

  letter-spacing: -0.05em;
  border-radius: 20px;
  color: #5aa885;
}
