.employes {
  font-family: "InterRegular";
  background-color: white;
  border: 1px solid #e3e3e3;
  border-radius: 3px;
  margin-top: 5rem;

  header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    h1 {
      font-size: 32px;
      line-height: 40px;
      font-family: "FaktumBold";
      letter-spacing: -0.05em;
      padding: 32px 50px;
      color: $secondary-color;
    }
  }

  .search-bar {
    font-family: "InterRegular";
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    line-height: 18px;
    padding: 8px;
    letter-spacing: -0.05em;
    color: $secondary-color;
    background-color: white;
    width: 250px;
    border: 1px solid $tertiary-color;
    border-radius: 5px;
    margin-right: 20px;
  }

  .descrip-totals {
    display: flex;
    align-items: center;
    border: 1px solid #e5e9ef;
    border-radius: 10px;
    padding: 24px;

    background: #ffffff;
    // border: 1px solid #e5e9ef;
    // border-radius: 10px;
    // box-shadow: 0px 4px 4px rgba(215, 215, 215, 0.25);

    p {
      font-family: "InterMedium";
      margin-right: 5px;
      font-size: 16px;
      line-height: 17px;
      /* identical to box height */

      letter-spacing: -0.05em;

      color: $secondary-color;
    }

    span {
      display: flex;
      font-family: "InterSemiBold";
      font-style: normal;
      font-weight: 700;
      font-size: 20px;
      line-height: 24px;
      letter-spacing: -0.05em;

      color: $secondary-color;
    }
  }

  .search-create {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 32px 50px;
  }

  .img-table-employe {
    overflow: hidden;
    border-radius: 2.5rem;
    text-decoration: none;
    border: 0px;
    background-color: transparent;
    padding: 0px;
    width: 2.5rem;
    min-width: 2.5rem;
    height: 2.5rem;
    object-fit: cover;
    margin-right: 15px;
  }

  .import-search-div {
    display: flex;
    justify-content: space-between;
  }

  button {
    font-family: "InterMedium";

    font-size: 14px;
    line-height: 18px;
    padding: 10px 14px;
    letter-spacing: -0.05em;
    color: white;

    background: $primary-color;
    border-radius: 5px;
    cursor: pointer;
    border: none;

    &:hover {
      background: $hover-primary-color;
    }
  }

  .total-mois {
    font-family: "InterMedium";
    font-size: 14px;
    line-height: 17px;
    letter-spacing: -0.05em;
    color: #999ca6;
    text-transform: uppercase;
    padding: 22px 22px;
  }
}

.container-form {
  display: flex;
  flex-direction: column;
  padding-bottom: 3rem;
  background-color: white;

  h2 {
    font-family: "InterMedium";
    font-size: 26px;
    margin-left: 4.3rem;
    line-height: 18px;
    margin-bottom: 15px;
    letter-spacing: -0.05em;
    color: $secondary-color;
  }

  .label-input {
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    color: black;
  }

  input {
    width: 325px;
    height: 36px;
    padding-left: 12px;
    padding-right: 30px;
    background: #ffffff;
    border: 1px solid #e3e3e3;
    border-radius: 3px;
    line-height: 34px;
    font-size: 14px;
    color: $secondary-color;
  }

  label {
    display: inline-block;
    font-size: 14px;
    font-weight: 500;
    color: #c1c2c5;
    word-break: break-word;
    cursor: default;
    font-family: "InterRegular";
    font-size: 14px;
    letter-spacing: -0.05em;
    color: #000000;

    span {
      color: red;
    }
  }

  .container-label-input {
    max-width: 1112px;
    flex-direction: row;
    display: flex;
    justify-content: space-evenly;
  }

  .container-label-input-2 {
    margin-left: 4.3rem;
  }

  .input-file {
    font-family: "FaktumRegular";
    content: "Upload Logo";
    background: $tertiary-color;
    border: 1px solid #e3e3e3;
    border-radius: 4px;
  }
  .input-file::-webkit-file-upload-button {
    visibility: hidden;
  }

  .input-file::before {
    content: "Upload photo";
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .container-button {
    display: flex;
    width: 100%;
    justify-content: right;
    padding-right: 4.3rem;
  }

  button {
    font-family: "InterMedium";
    margin-top: 1rem;
    font-size: 14px;
    line-height: 18px;
    padding: 10px 14px;
    letter-spacing: -0.05em;
    color: white;

    background: $primary-color;
    border-radius: 5px;
    cursor: pointer;
    border: none;

    &:hover {
      background: $hover-primary-color;
    }
  }
}

// table {
//   font-family: arial, sans-serif;
//   border-collapse: collapse;
//   width: 100%;
// }

// td,
// th {
//   border: 1px solid #dddddd;
//   text-align: left;
//   padding: 8px;
// }

// tr:nth-child(even) {
//   background-color: #c79b9b;
// }
::-webkit-input-placeholder {
  /* Pour les navigateurs basés sur WebKit (Chrome, Safari) */
  color: $tertiary-color; /* Remplacez par la couleur souhaitée */
}

:-moz-placeholder {
  /* Pour les anciennes versions de Firefox */
  color: $tertiary-color; /* Remplacez par la couleur souhaitée */
}

::-moz-placeholder {
  /* Pour les nouvelles versions de Firefox */
  color: $tertiary-color; /* Remplacez par la couleur souhaitée */
}

:-ms-input-placeholder {
  /* Pour Internet Explorer */
  color: $tertiary-color; /* Remplacez par la couleur souhaitée */
}

.container-table {
  .header {
    background-color: white;
  }
}

.mantine-Modal-header {
  background-color: white;
}
.mantine-Modal-modal {
  background-color: white;
}

.mantine-Modal-close {
  color: red;
}
