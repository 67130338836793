.container-login {
  display: flex;

  height: 100vh;

  .left-container {
    padding: 0 6.3rem;
    width: 45rem;
    justify-content: center;
    display: flex;
    flex-direction: column;
    margin-bottom: 6rem;
  }

  .titles {
    display: flex;
    flex-direction: column;
    text-align: center;
    margin-bottom: 5.5rem;

    h1 {
      color: $secondary-color;
      font-size: 2rem;
      font-family: "InterSemiBold";
      font-weight: 700;
      line-height: normal;
      letter-spacing: -0.09375rem;
    }

    h2 {
      color: #909090;
      font-size: 1rem;
      font-family: "InterRegular";
      font-weight: 400;
      line-height: normal;
      letter-spacing: -0.04688rem;
    }
  }

  .form {
    display: flex;
    flex-direction: column;
  }

  .right-container {
    width: 100%;
    background-repeat: no-repeat;
    background-origin: border-box;
    background-size: cover;
    background-image: url("../../assets/station-spatiale.jpg");

    .container-rd {
      display: flex;
      justify-content: right;
      max-height: 100vh;
      padding-right: 2rem;
      height: 90%;
    }
    .yn-group-div {
      display: flex;
      justify-content: right;
      padding: 2rem;
    }

    .rd-div {
      display: flex;
      padding: 2rem;
      flex-direction: column;
      justify-content: end;
      text-align: right;
      p {
        color: #fff;
        font-family: "InterRegular";
        font-size: 1rem;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        letter-spacing: -0.04688rem;
      }
      span {
        color: #397ade;
        font-family: "InterRegular";
        font-size: 1rem;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        letter-spacing: -0.04688rem;
      }
    }
  }
}
