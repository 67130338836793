/* Styles CSS */
.custom-select {
  height: 36px;
  padding-left: 12px;
  padding-right: 30px;
  background: #ffffff;
  border: 1px solid #e3e3e3;
  border-radius: 3px;
  line-height: 34px;
  font-size: 14px;
  color: $secondary-color;

  cursor: pointer;
  margin-right: 12px;
}

/* Style pour les options */
.custom-select .option {
  background-color: #f7f7f7; /* Définissez la couleur de fond des options */
  color: #333; /* Définissez la couleur du texte des options */
  border: 1px solid red;
}
