.header-create-BDC {
  padding-top: 32px;
  h1 {
    text-align: left;
    font-weight: 600;
    font-size: 32px;
    line-height: 40px;
    font-family: "FaktumSemiBold";
    color: $secondary-color;
  }
}

.container-create-BDC {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  .div-creation-bdc {
    input {
      width: 325px;
      height: 36px;
      padding-left: 12px;
      padding-right: 30px;
      background: #ffffff;
      border: 1px solid #e3e3e3;
      border-radius: 3px;
      line-height: 34px;
      font-size: 14px;
      color: $secondary-color;
    }
    input[disabled] {
      /* styles à appliquer lorsque l'input est désactivé */
      background-color: #f0f0f0;
      color: #888888;
      cursor: not-allowed;
      /* autres styles personnalisés */
    }

    label {
      display: inline-block;
      font-size: 14px;
      font-weight: 500;
      color: #c1c2c5;
      word-break: break-word;
      cursor: default;
      font-family: "InterRegular";
      font-size: 14px;
      letter-spacing: -0.05em;
      color: #000000;

      span {
        color: red;
      }
    }
  }

  h2 {
    font-family: "InterMedium";
    font-size: 21px;
    line-height: 24px;
    letter-spacing: -0.05em;
    margin-top: 60px;
    text-align: center;
    color: $secondary-color;
  }

  h3 {
    margin-bottom: 35px;
    font-family: "InterRegular";
    font-size: 14px;
    /* identical to box height */
    text-align: center;
    letter-spacing: -0.05em;

    color: #9197a0;
  }
  .btn-update {
    font-family: "FaktumRegular";
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    line-height: 18px;
    padding: 8px;
    letter-spacing: -0.05em;
    color: white;
    width: 217px;
    background: $primary-color;
    border-radius: 5px;
    margin-top: 35px;
    border: none;
    cursor: pointer;
    margin-bottom: 5rem;
  }

  .btn-create-client {
    font-family: "InterMedium";

    font-size: 14px;
    line-height: 18px;
    padding: 8px;
    letter-spacing: -0.05em;
    color: white;
    width: 325px;
    background: $primary-color;
    border-radius: 5px;

    border: none;
    margin-bottom: 2rem;
    cursor: pointer;
    &:hover {
      background: $hover-primary-color;
    }
  }

  .creationBDC-client {
    display: flex;
    align-items: left;
    flex-direction: column;
    width: 100%;
    h5 {
      font-family: "InterMedium";

      font-size: 20px;
      line-height: 24px;
      letter-spacing: -0.05em;

      color: $secondary-color;
    }
    h6 {
      color: #9197a0;
    }
    button {
      font-family: "InterMedium";

      font-size: 14px;
      line-height: 18px;
      padding: 8px;
      letter-spacing: -0.05em;
      color: white;
      width: 325px;
      background: $primary-color;
      border-radius: 5px;
      margin-top: 35px;
      border: none;
      cursor: pointer;
      &:hover {
        background: $hover-primary-color;
      }
    }
  }

  .creationBDC-bdc {
    display: flex;
    flex-direction: column;

    .container-label-input {
      max-width: 1112px;
      flex-direction: row;
      display: flex;
    }

    .label-input,
    .label-input-2 {
      margin-top: 20px;
      display: flex;
      flex-direction: column;
    }

    .label-input-2 {
      margin-left: 5rem;
    }

    .container-date-picker {
      display: flex;
    }

    .span-lastValue {
      font-size: 14px;
      max-width: 325px;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }
  }

  .creationBDC-attribution {
    // .select-total {
    //   display: flex;
    //   align-items: center;
    // }
    .edit-msg {
      font-size: 14px;
      line-height: 18px;
      margin-top: 5px;
      letter-spacing: -0.05em;
      color: #de4341;
    }

    .remove-consultant-attribution {
      cursor: pointer;
      &:hover {
        filter: invert(0) sepia(0) saturate(1) hue-rotate(0deg) brightness(5);
      }
    }
    input {
      width: 325px;
      height: 36px;
      padding-left: 12px;
      padding-right: 30px;
      background: #ffffff;
      border: 1px solid #e3e3e3;
      border-radius: 3px;
      line-height: 34px;
      font-size: 14px;
      color: $secondary-color;
    }
    input[disabled] {
      /* styles à appliquer lorsque l'input est désactivé */
      background-color: #f0f0f0;
      color: #888888;
      cursor: not-allowed;
      /* autres styles personnalisés */
    }

    label {
      display: inline-block;
      font-size: 14px;
      font-weight: 500;
      color: #c1c2c5;
      word-break: break-word;
      cursor: default;
      font-family: "InterRegular";
      font-size: 14px;
      letter-spacing: -0.05em;
      color: #000000;

      span {
        color: red;
      }
    }
    .select-input {
      display: flex;
      align-items: end;
    }

    .label-input,
    .label-input-2 {
      margin-top: 20px;
      display: flex;
      flex-direction: column;
    }

    .label-input-2 {
      margin-left: 5rem;
    }

    .div-add-another {
      display: flex;
      justify-content: right;
      margin-top: 20px;
      margin-bottom: 15px;
    }

    .span-add-another {
      display: flex;
      justify-content: center;
      align-items: center;
      font-family: "InterRegular";
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 17px;
      /* identical to box height */

      letter-spacing: -0.05em;

      color: $primary-color;
      width: 159px;
      height: 36px;
      border: 1px solid $primary-color;
      border-radius: 5px;
      cursor: pointer;

      &:hover {
        background: $tertiary-color;
      }
    }
    .total-BDC {
      width: 732px;
      padding: 30px 70px;
      height: 123px;
      background: white;
      display: flex;
      flex-direction: column;
      border: 1px solid #e5e9ef;
      border-radius: 10px;
      span {
        font-family: "InterBold";
        margin-top: 0.5rem;
        font-size: 20px;
        line-height: 24px;
        letter-spacing: -0.05em;

        color: #000000;
      }
      .div-total-euro {
        display: flex;
        width: 100%;
        align-items: center;
        justify-content: space-between;
        flex-direction: row;
        font-family: "InterMedium";
        color: $secondary-color;
        font-size: 16px;
        letter-spacing: -0.05em;
      }
    }

    .add-drive-link {
      display: flex;
      flex-direction: column;
      margin-bottom: 1rem;
    }

    .drive-link {
      display: flex;
      align-items: center;
      margin-bottom: 1rem;
      a {
        display: inline-block;
        font-size: 15px;
        font-weight: 500;
        word-break: break-word;
        cursor: default;
        font-family: "InterRegular";
        font-size: 14px;
        letter-spacing: -0.05em;
        color: #000000;
        cursor: pointer;

        &:hover {
          color: #1b4aca;
          
        }
      }

      img {
        cursor: pointer;
        &:hover {
          filter: brightness(0) saturate(100%) invert(19%) sepia(80%) saturate(4921%) hue-rotate(228deg) brightness(101%) contrast(106%);
          
        }
      }
     
    }

    .add-comment {
      textarea {
        width: 732px;
        padding: 12px;
        height: 90px;
        color: #797d91;
        font-size: 14px;
        background: white;
        display: flex;
        flex-direction: column;
        border: 1px solid #e5e9ef;
        border-radius: 10px;
        
      }
    }
  }

  .creationBDC-autre {
    .label-input {
      flex-direction: column;
      display: flex;
      justify-content: space-between;
    }
  }

  .div-img-delete {
    display: flex;
    justify-content: right;
  }
  .img-delete {
    margin-top: 1rem;

    cursor: pointer;

    &:hover {
      text-decoration: underline;
    }
  }

  .btn-create-bdc {
    margin-top: 20px;
    width: 732px;
    background: $primary-color;
    padding: 8px;
    font-family: "InterMedium";
    border-radius: 5px;
    color: white;
    border: none;

    font-family: 14px;
    cursor: pointer;
    &:hover {
      background: $hover-primary-color;
    }
  }
}

.modal-create-client {
  display: flex;
  flex-direction: column;
  h2 {
    font-family: "InterSemiBold";

    font-size: 22px;
    line-height: 22px;
    /* identical to box height */

    letter-spacing: -0.05em;
    color: $secondary-color;
    margin-bottom: 5px;
  }

  h3 {
    font-family: "InterRegular";
    margin-bottom: 3rem;
    font-size: 14px;
    line-height: 17px;
    letter-spacing: -0.05em;

    color: #80899b;
  }

  .div-create-client {
    display: flex;
    justify-content: right;
    margin-right: 50px;
    margin-top: 2rem;
  }

  button {
    font-family: "InterMedium";

    font-size: 14px;
    line-height: 18px;
    padding: 10px 14px;
    letter-spacing: -0.05em;
    color: white;

    background: $primary-color;
    border-radius: 5px;
    cursor: pointer;
    border: none;

    &:hover {
      background: $hover-primary-color;
    }
  }
  input {
    width: 325px;
    height: 36px;

    background: #ffffff;
    border: 1px solid #e3e3e3;
    border-radius: 3px;
    line-height: 34px;
    font-size: 14px;
    color: $secondary-color;
    margin-left: 15px;
  }

  label {
    font-family: "InterMedium";

    font-size: 14px;
    line-height: 17px;
    /* identical to box height */

    letter-spacing: -0.05em;

    color: $secondary-color;

    span {
      color: red;
    }
  }

  .label-input {
    margin-top: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
