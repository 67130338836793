.userBDC {
  margin-top: 5rem;
  header {
    background-color: white;
    border: 1px solid #e3e3e3;
    border-radius: 5px;
    padding: 50px;

    .container-user-infos {
      display: flex;
      justify-content: space-between;
      img {
        width: 80px;
        height: 80px;
        border-radius: 100px;
        object-fit: cover;
      }
      .img-user-name {
        display: flex;
        flex-direction: row;

        .user-infos {
          margin-left: 1rem;
          display: flex;
          flex-direction: column;

          h1 {
            font-family: "InterBold";

            font-size: 24px;
            line-height: 29px;
            /* identical to box height */

            letter-spacing: -0.05em;

            color: $secondary-color;
          }

          h2 {
            display: flex;
            font-family: "InterMedium";
            letter-spacing: -0.05em;
            font-size: 16px;
            line-height: 18px;
            /* identical to box height */
            letter-spacing: -0.05em;
            color: $primary-color;
          }
          h3 {
            display: flex;
            font-family: "InterRegular";
            letter-spacing: -0.05em;
            font-size: 16px;
            line-height: 18px;
            /* identical to box height */
            letter-spacing: -0.05em;
            color: $secondary-color;
          }
        }
      }

      .container-user-stats {
        border: 1px solid #e5e9ef;
        border-radius: 10px;
        padding: 24px;
        p {
          margin-left: 6px;
          font-family: "InterBold";

          font-size: 20px;
          line-height: 24px;
          letter-spacing: -0.05em;
          color: $secondary-color;
        }
        span {
          font-family: "InterMedium";
          display: flex;
          align-items: center;
          font-size: 16px;
          line-height: 17px;
          /* identical to box height */

          letter-spacing: -0.05em;

          color: $secondary-color;
        }
      }
    }

    h3 {
      display: flex;
    }
    img {
      width: 250px;
      border-radius: 15px;
    }
    span {
      display: flex;
    }
  }

  .table-bdcbyclient {
    margin-top: 3rem;
    background-color: white;
    padding: 32px 0px;
    border: 1px solid #e3e3e3;
    border-radius: 5px;

    .container-gape {
      display: flex;
      justify-content: right;
      margin-right: 50px;
    }

    .total-mois {
      font-family: "InterMedium";
      font-size: 14px;
      line-height: 17px;
      letter-spacing: -0.05em;
      color: #999ca6;
      text-transform: uppercase;
      margin-left: 13px;
    }
  }

  .chart-container {
    margin-top: 3rem;
    display: flex;
    justify-content: center;
  }

  .nav-control {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 2rem;
  }

  .nav-userbdc {
    width: 200px;
    height: 40px;
    background: #f0f3f8;
    border-radius: 5px;
    font-family: "InterMedium";
    justify-content: space-around;
    font-size: 15px;
    line-height: 20px;
    /* identical to box height, or 143% */
    display: flex;
    align-items: center;
    letter-spacing: -0.05em;
    margin-left: 50px;

    color: #a6aebc;
    button {
      border: none;
      background: #f0f3f8;
      border-radius: 5px;
      color: $quaternary-color;
      padding: 2px 12px;
      text-decoration: none;
      &:hover {
        color: $secondary-color;
        /*  border-color: #6935d7; */
      }
    }
    button:focus {
      padding: 7px;
      color: $quinary-color;
      background: white;
      border-radius: 5px;
      border: none;
    }
  }
}
