.icon-action {
  cursor: pointer;
  &:hover {
    filter: invert(0) sepia(0) saturate(1) hue-rotate(0deg) brightness(5);
  }
}

.tr-bdc-list {
  td {
    width: 5rem;
  }
}

.bdc-page {
  font-family: "InterRegular";
  background-color: white;
  border: 1px solid #e3e3e3;
  border-radius: 3px;
  margin-top: 5rem;
  min-height: 100vh;

  .th {
    cursor: "pointer";
    width: 10% !important;
    transition: 0.3ms;
    &:hover {
      // filter: brightness(80%);
      background: #f1f1f1;
    }
  }

  .descrip-totals {
    display: flex;
    align-items: center;

    background: #ffffff;
    // border: 1px solid #e5e9ef;
    // border-radius: 10px;
    // box-shadow: 0px 4px 4px rgba(215, 215, 215, 0.25);

    p {
      font-family: "InterMedium";
      margin-right: 5px;
      font-size: 16px;
      line-height: 17px;
      /* identical to box height */

      letter-spacing: -0.05em;

      color: $secondary-color;
    }

    span {
      display: flex;
      font-family: "InterSemiBold";
      font-style: normal;
      font-weight: 700;
      font-size: 20px;
      line-height: 24px;
      letter-spacing: -0.05em;

      color: $secondary-color;
    }
  }

  h1 {
    font-size: 32px;
    line-height: 40px;
    font-family: "FaktumBold";
    letter-spacing: -0.05em;
    padding: 32px 50px;
    color: $secondary-color;
  }

  .search-create {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 32px 50px;
  }

  button {
    font-family: "InterMedium";

    font-size: 14px;
    line-height: 18px;
    padding: 10px 14px;
    letter-spacing: -0.05em;
    color: white;

    background: $primary-color;
    border-radius: 5px;
    cursor: pointer;
    border: none;

    &:hover {
      background: $hover-primary-color;
    }
  }

  .search-bar {
    font-family: "InterRegular";
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    line-height: 18px;
    padding: 8px;
    letter-spacing: -0.05em;
    color: $secondary-color;
    background-color: white;
    width: 250px;
    border: 1px solid $tertiary-color;
    border-radius: 5px;
    margin-right: 20px;
  }
}

.scroll-to-top {
  position: fixed;
  font-size: 14px;
  bottom: 20px; /* Ajustez la position verticale selon vos préférences */
  right: 20px; /* Ajustez la position horizontale selon vos préférences */
  z-index: 1000; /* Pour s'assurer que le bouton est au-dessus du contenu */
  background-color: $primary-color; /* Couleur de fond du bouton */
  color: #fff; /* Couleur du texte du bouton */
  border: none;
  border-radius: 5px; /* Pour obtenir une forme de cercle */
  padding: 10px;
  font-size: 20px;
  cursor: pointer;
}

.scroll-to-top:hover {
  background-color: #0056b3; /* Couleur de fond au survol */
}

.th {
  cursor: "pointer";
  //width: 10% !important;
  transition: 0.3ms;
  &:hover {
    // filter: brightness(80%);
    background: #f1f1f1;
  }
}
