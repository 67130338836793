.importBDC-page {
  font-family: "InterRegular";
  background-color: white;
  border: 1px solid #e3e3e3;
  border-radius: 3px;
  margin-top: 5rem;

  h1 {
    font-size: 32px;
    line-height: 40px;
    font-family: "FaktumBold";
    letter-spacing: -0.05em;
    padding: 32px 50px;
    color: $secondary-color;
  }

  h2 {
    font-size: 16px;
    font-weight: 500;
    letter-spacing: -0.05em;

    // color: $quaternary-color;
  }

  td,
  th {
    max-width: 200px;
  }
  td {
  }

  .input-import {
    margin-left: 5px;
  }

  .exemple-link {
    padding-left: 50px;
    margin-bottom: 5rem;
    margin-top: 5px;
    a {
      font-size: 15px;
      cursor: pointer;
      margin-left: 3px;
      font-family: "InterSemiBold";
      letter-spacing: -0.05em;
      color: $primary-color;
      text-decoration: underline;
      &:hover {
        text-decoration: none;
      }
    }

    .container-warning-msg {
      color: red;
      margin-top: 1rem;

      .logo-and-title {
        display: flex;
        margin-bottom: 3px;
        h3 {
          margin-left: 3px;

          font-weight: 600;
        }
      }

      p {
        font-size: 16px;
        letter-spacing: -0.05em;
      }
    }
  }

  .ondrop-div {
    margin: 32px 50px 0px 50px;
    min-height: 250px;
    border: dashed 2px $primary-color;
    border-spacing: 150px;
    border-radius: 20px;
    background-color: white;

    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 16px;
    font-family: "InterMedium";
    color: $secondary-color;

    img {
      width: 35px;
      margin-right: 5px;
    }
  }
  .div-button-import {
    display: flex;
    margin: 2rem;
    justify-content: right;
    button {
      font-family: "InterMedium";

      font-size: 14px;
      line-height: 18px;
      padding: 10px 14px;
      letter-spacing: -0.05em;
      color: white;

      background: $primary-color;
      border-radius: 5px;
      cursor: pointer;
      border: none;

      &:hover {
        background: $hover-primary-color;
      }
    }
  }
}

// button from file import /!\

.disabled-button {
  background-color: gray !important;
  /* Ajoutez d'autres styles de votre choix */
}

.succes-import-element {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.valid-message {
  background-color: #d4edda; /* Couleur de fond verte */
  color: #155724; /* Couleur de texte foncé */
  padding: 10px; /* Espace intérieur autour du texte */
  border-radius: 5px; /* Coins arrondis */
}

.appear-button {
  opacity: 0;
  transition: opacity 0.5s ease-in-out;
}

.appear-button.visible {
  opacity: 1;
}
