.accueil {
  //   width: 98%;
  //margin: 0px auto;
  //   text-align: left;
  display: flex;
  justify-content: center;
  scrollbar-color: light;
}

.global-container {
  padding-bottom: 5rem;
}

@media (max-width: 1200px) {
  .global-container {
    width: 1180px;
  }
}

@media (min-width: 1200px) {
  .global-container {
    width: 1180px;
  }
}

@media (min-width: 1300px) {
  .global-container {
    width: 1250px;
  }
}

@media (min-width: 1400px) {
  .global-container {
    width: 1350px;
  }
}
@media (min-width: 1500px) {
  .global-container {
    width: 1450px;
  }
}

@media (min-width: 1600px) {
  .global-container {
    width: 1550px;
  }
}

@media (min-width: 1700px) {
  .global-container {
    width: 1680px;
  }
}
