.table {
  font-family: "InterRegular";
  font-size: 14px;
  line-height: 17px;
  letter-spacing: -0.05em;

  width: 100%;

  .th {
    color: #999ca6;
    width: 100%;
    font-weight: 600;
    padding: 10px 10px;
  }

  thead {
    // border-top-right-radius: 50px;
    color: #999ca6;
    background-color: white;
    text-transform: uppercase;
    font-size: 13px;

    tr {
      // border-top-right-radius: 50px;
      // padding: 22px 22px !important;

      th {
        padding: 12px;
        text-align: left;
        min-width: fit-content;
      }
    }
  }

  tbody {
    tr:nth-child(even) {
      background-color: #f2f2f2; /* Couleur de fond pour les rangées paires */
    }

    tr:nth-child(odd) {
      background-color: #ffffff; /* Couleur de fond pour les rangées impaires */
    }
    tr {
      border-bottom: 1px solid #e6e6e6;
    }
    td {
      padding: 12px;
      color: $secondary-color;
      &:hover {
        background-color: #f1f1f1;
      }
      // &.no-background {
      //   background-color: none;
      //   width: fit-content;
      //   padding: 5px;
      //   border-radius: 5px;
      // }

      // &.pink-background {
      //   background-color: pink;
      //   color: black;
      //   width: fit-content;
      //   padding: 5px;
      //   border-radius: 5px;
      // }

      // &.green-background {
      //   background: green !important;
      //   color: white !important;
      //   width: fit-content;
      //   padding: 5px;
      //   border-radius: 5px;
      // }

      // &.dark-green-background {
      //   background-color: darkgreen !important;
      //   color: white !important;
      //   width: fit-content;
      //   padding: 5px;
      //   border-radius: 5px;
      // }

      // &.orange-background {
      //   background-color: orange !important;
      //   color: black !important;
      //   width: fit-content;
      //   padding: 5px;
      //   border-radius: 5px;
      // }
      //background-color: rgb(92, 92, 92);
    }
  }
}

.table-total {
  font-family: "InterRegular";
  font-size: 14px;
  line-height: 17px;
  letter-spacing: -0.05em;
  overflow: scroll !important;
  .th {
    color: #999ca6;
    width: 100%;
    font-weight: 600;
    padding: 10px 10px;
  }

  thead {
    // border-top-right-radius: 50px;
    color: #999ca6;
    background-color: white;
    text-transform: uppercase;
    font-size: 14px;

    tr {
      // border-top-right-radius: 50px;
      // padding: 22px 22px !important;

      th {
        padding: 12px;
        text-align: left;
        min-width: fit-content;
      }
    }
  }

  tbody {
    tr:nth-child(even) {
      background-color: #f2f2f2; /* Couleur de fond pour les rangées paires */
    }

    tr:nth-child(odd) {
      background-color: #ffffff; /* Couleur de fond pour les rangées impaires */
    }
    tr {
    }
    td {
      padding: 12px;
      color: $secondary-color;
      &:hover {
        background-color: #f1f1f1;
      }
    }
  }
}

.green-background {
  background: green !important;
  color: white !important;
  width: fit-content !important;
  padding: 5px !important;
  border-radius: 5px;
}

.no-background {
  background-color: none;
  width: fit-content;
  padding: 5px;
  border-radius: 5px;
  width: 12px;
}
.pink-background {
  background-color: pink !important;
  color: black !important;
  width: fit-content !important;
  padding: 5px !important;
  border-radius: 5px;
}

.beige-background {
  background-color: #f8dfb1 !important;
  color: black !important;
  width: fit-content !important;
  padding: 5px !important;
  border-radius: 5px;
}
.dark-green-background {
  background-color: darkgreen !important;
  color: white !important;
  width: fit-content !important;
  padding: 5px !important;
  border-radius: 5px;
}
.orange-background {
  background-color: orange !important;
  color: black !important;
  width: fit-content;
  padding: 5px;
  border-radius: 5px;
}
